<template>
  <slot />
  <a-drawer
    :title="`${ section ? 'Edit' : 'Add' } Section`"
    :width="560"
    @close="$emit('cancel')"
    :visible="visible"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Title"
        name="title"
        :rules="[
          { required: true, message: 'Title is required.' },
        ]"
      >
        <a-input
          v-model:value="values.title"
          :maxlength="50"
          name="title"
        />
      </a-form-item>
      <a-form-item
        label="Description"
        name="description"
      >
        <a-textarea
          v-model:value="values.description"
          :maxlength="250"
          name="description"
          :rows="5"
          showCount
        />
      </a-form-item>
      <a-form-item
        label="Default Call-to-Action"
        name="action"
      >
        <a-select
          v-model:value="values.action"
          allowClear
          :loading="loadingActions"
          name="action"
          placeholder="Select call-to-action"
        >
          <a-select-option v-for="action in actions" :value="action.id">
            {{ action.title }}
          </a-select-option>
        </a-select>
        <small>
          <p class="text-muted mt-2">Fallback CTA for articles categorized in this section that don’t have a CTA specified.</p>
        </small>
      </a-form-item>
    </a-form>
    <template #extra>
      <a-button type="primary" html-type="submit" :loading="loading" @click="onFinish">
        Save
      </a-button>
    </template>
  </a-drawer>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    section: Object,
  },

  data() {
    return {
      actions: [],
      loading: false,
      loadingActions: false,
      values: {
        action: this.section && this.section.action,
        description: this.section && this.section.description,
        title: this.section && this.section.title,
      },
    }
  },

  async mounted() {
    await this.listActions()
  },

  methods: {
    async listActions() {
      try {
        this.loadingActions = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/actions`)

        this.actions = data.actions
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loadingActions = false
      }
    },
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        if (this.section) {
          var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/sections/${ this.section.id }`, values)
        } else {
          var { data } = await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/sections`, values)
        }

        this.$message.success('Section has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
