<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="h3 mb-0">
      Sections <small v-if="sectionsCount !== null" class="text-muted">({{ sectionsCount }})</small>
    </h1>
    <SectionDrawer
      :visible="visible"
      @cancel="visible = false"
      @success="onSuccess"
    >
      <a-button type="primary" shape="circle" size="large" :disabled="loading" @click="visible = true">
        <PlusIcon class="icon" />
      </a-button>
    </SectionDrawer>
  </div>
  <BaseSpinner :loading="loading">
    <SectionItem
      v-for="(section, i) in sections"
      :section="section"
      class="py-3"
      :class="{ 'border-bottom': i < sectionsCount - 1 }"
      @change="listSections"
    />
    <BaseEmpty v-if="sectionsCount === 0">
      Create the first section for your publication.
    </BaseEmpty>
  </BaseSpinner>
  <BasePagination
    class="mt-4"
    v-model="filters.page"
    :hasMore="hasMore"
    :pageCount="Math.ceil(sectionsCount / filters.limit)"
  />
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import BaseEmpty from '@/components/Base/BaseEmpty'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import SectionDrawer from '@/components/Section/SectionDrawer'
import SectionItem from '@/components/Section/SectionItem'

export default {
  components: {
    BaseEmpty,
    BasePagination,
    BaseSpinner,
    PlusIcon,
    SectionDrawer,
    SectionItem,
  },

  data() {
    return {
      loading: false,
      visible: false,
      sections: [],
      sectionsCount: null,
      hasMore: false,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
    }
  },

  async mounted() {
    await this.listSections()
  },

  methods: {
    async listSections() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/sections`, {
          params: this.filters,
        })

        this.sections = data.sections
        this.sectionsCount = data.sectionsCount
        this.hasMore = data.hasMore
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
    async onSuccess() {
      this.visible = false
      await this.listSections()
    },
  },

  watch: {
    'filters.page'(value) {
      this.$router.push({ name: 'publications-id-sections', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
