<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-8 mb-4 mb-lg-0">
        <h2 class="h6">{{ section.title }}</h2>
        <p>{{ section.description }}</p>
        <a-tag v-if="section.articlesCount">{{ section.articlesCount }}  {{ section.articlesCount === 1 ? 'Article' : 'Articles' }}</a-tag>
      </div>
      <div class="col-12 col-lg-2 mb-4">
        <p class="text-overline">Edited</p>
        <div>
          <a-tooltip :title="dateFormatted(section.updatedAt)" placement="bottom">
            {{ dateFromNow(section.updatedAt) }}
          </a-tooltip>
        </div>
      </div>
      <div class="col-12 col-lg-2 text-lg-end">
        <a-dropdown-button
          size="small"
          :trigger="['click']"
          :disabled="!$store.getters['publication/isAdmin']"
          @click="visible.update = true"
        >
          <SectionDrawer
            :visible="visible.update"
            :section="section"
            @cancel="visible.update = false"
            @success="onSuccess('update')"
          >
            Edit
          </SectionDrawer>
          <template #overlay>
            <a-menu @click="onMenuClick">
              <a-menu-item class="text-danger" key="delete">
                <SectionDelete
                  :visible="visible.delete"
                  :section="section"
                  @cancel="visible.delete = false"
                  @success="onSuccess('delete')"
                >
                  <TrashIcon class="icon me-2" />Delete
                </SectionDelete>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown-button>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'
import SectionDelete from '@/components/Section/SectionDelete'
import SectionDrawer from '@/components/Section/SectionDrawer'

export default {
  components: {
    SectionDelete,
    SectionDrawer,
    TrashIcon,
  },

  props: {
    section: Object,
  },

  data() {
    return {
      visible: {
        update: false,
        delete: false,
      },
    }
  },

  methods: {
    dateFromNow(date) {
      return moment(date).fromNow()
    },
    dateFormatted(date) {
      return moment(date).format('LLLL')
    },
    onSuccess(key) {
      this.visible[key] = false
      this.$emit('change')
    },
    onMenuClick(e) {
      this.visible[e.key] = true
    },
  },
}
</script>
